<template>
  <div>
    <h1>Bienvenido a su apartado para capturar sus vehiculos</h1>
    <b-form @reset="onReset" >
      <b-form-group id="input-group-1" label="Marca:" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="vehiculoActual.marca"
          placeholder="Ingrese la marca del vehiculo"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Submarca:" label-for="input-2">
        <b-form-input
          v-model="vehiculoActual.submarca"
          placeholder="Ingrese la submarca del vehiculo">
        </b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3" label="Modelo:" label-for="input-3">
        <b-form-input
          v-model="vehiculoActual.modelo"
          placeholder="Ingrese el modelo del vehiculo">
        </b-form-input>
      </b-form-group>

      <b-form-group id="input-group-4" label="Color:" label-for="input-4">
        <b-form-input
          v-model="vehiculoActual.color"
          placeholder="Ingrese el color del vehiculo">
        </b-form-input>
      </b-form-group>

      <b-form-group id="input-group-5" label="Serie:" label-for="input-5">
        <b-form-input
          v-model="vehiculoActual.serie"
          placeholder="Ingrese el número de serie del vehiculo">
        </b-form-input>
      </b-form-group>

      <b-form-group id="input-group-6" label="Número economico:" label-for="input-6">
        <b-form-input
          v-model="vehiculoActual.numero_economico"
          placeholder="Ingrese el número economico del vehiculo">
        </b-form-input>
      </b-form-group>

      <b-form-group id="input-group-7" label="Placas:" label-for="input-7">
        <b-form-input
          v-model="vehiculoActual.placas"
          placeholder="Ingrese las placas del vehiculo">
        </b-form-input>
      </b-form-group>

      <b-form-group id="input-group-8" label="Número de inventario:" label-for="input-8">
        <b-form-input
          v-model="vehiculoActual.n_inventario"
          placeholder="Ingrese el número de inventario">
        </b-form-input>
      </b-form-group>

      <b-form-group id="input-group-9" label="Número de litros:" label-for="input-9">
        <b-form-input
          v-model="vehiculoActual.n_litros"
          placeholder="Ingrese el número de litros">
        </b-form-input>
      </b-form-group>

    <!--
    <b-button @click="agregarVehiculoActual()" variant="success">
      Guardar vehiculo
    </b-button>


    <b-button type="reset" variant="danger">
      Borrar vehiculo
    </b-button>
    -->

    <b-button variant="dark" @click="enviarVehiculo" @dblclick="reloadPage">
      Enviar vehiculo
    </b-button>

    <b-button variant="dark" @click="reloadPage">
      Dar de alta otro vehiculo
    </b-button>

  
    <div>
    <br>
      <b-table striped hover :items="items"></b-table>
    </div>

    <br>
    <h6>Resumen Vehiculo</h6>
    {{vehiculoActual}}
    <br>
    <h6>Resumen Inventario Vehiculos</h6>
    {{inventario_Vehiculos}}

    <br>



    </b-form>

  </div>
</template>

<script>
import axios from 'axios'
  export default {
    data() {
      return {
        inventario_Vehiculos: {
          vehiculos:[
            {}
          ]
        },

        vehiculoActual:{
              marca:'',
              submarca:'',
              modelo:'',
              color:'',
              serie:'',
              numero_economico:'',
              placas:'',
              n_inventario:'',
              n_litros:''

            },

        items: [
          { marca: 'Ford', submarca: 'Figo', modelo: '2018', color: 'Rojo', serie:'ckjnj78374hbhhx', numero_economico:'003', placas: 'NWA3592', n_inventario: '02324', n_litros: '50'},

        ],
        
      }
    },
    methods: {
    
      onReset(event) {
        event.preventDefault()
        // Valores al Resetear encuesta

        this.vehiculoActual.marca=''
        this.vehiculoActual.submarca=''
        this.vehiculoActual.modelo=''
        this.vehiculoActual.color=''
        this.vehiculoActual.serie=''
        this.vehiculoActual.numero_economico=''
        this.vehiculoActual.placas=''

      },

      reloadPage() {
      window.location.reload();
    },
      
      enviarVehiculo(){
        this.inventario_Vehiculos.vehiculos.push(JSON.parse(JSON.stringify(this.vehiculoActual)));
          alert('Se guardaron correctamente los datos del vehiculo');

          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });
          },

    }
  }
</script>